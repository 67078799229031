import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import Layout from '../components/lms/layout';
import Button from '../atoms/button';
import SupportingDocumentCard from '../components/lms/supporting-doc-card';
import VideoListSwiper from '../components/lms/video-list-swiper';
import { wistiaVideoContentEntryParser } from '../helpers/parser';

const OverviewTemplate = ({
  pageContext: { locale = 'en-AU', learningModule, globals },
}) => {
  const learningModuleTitle = learningModule.title;
  const learningModuleSlug = learningModule.slug;

  const seo = {
    pageTitle: `${learningModuleTitle} - Revolution Learning Centre`,
    pageDecription: learningModule.description.description,
  };

  const videoList = learningModule.videoList.map((videoNode) =>
    wistiaVideoContentEntryParser(videoNode)
  );

  return (
    <Layout
      bookingLink={
        locale === 'en-NZ' ? globals.nzBookingLink : globals.bookingLink
      }
      locale={locale}
    >
      <Helmet
        htmlAttributes={{
          lang: 'en',
        }}
        title={seo.pageTitle}
        link={[
          {
            rel: 'stylesheet',
            href: 'https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap',
          },
        ]}
        meta={[
          {
            name: 'description',
            content: seo.pageDecription,
          },
          {
            name: 'robots',
            content: 'noindex',
          },
        ]}
      />

      <div className="pt-header-height-mobile lg:pt-header-height-desktop xl:container mobile:px-4 tablet:px-8 lg:px-16 mb-9">
        <p className="text-body-xs text-white mobile+tablet:whitespace-nowrap">
          <a
            className="underline hover:no-underline"
            href={`/academy${locale === 'en-NZ' ? '/nz' : ''}`}
          >
            <span>Learning Centre</span>
          </a>{' '}
          / {learningModuleTitle}
        </p>
      </div>

      <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16 mb-16">
        <div className="grid grid-cols-12">
          <div className=" col-start-1 col-span-full lg:col-span-8">
            <h1 className="u-h2 text-white mb-3">{learningModuleTitle}</h1>
            {learningModule.summary && learningModule.summary.summary && (
              <p className="u-body-lg text-white mb-10">
                {learningModule.summary.summary}
              </p>
            )}
            <Button
              as="a"
              label="Start training now"
              href={`/academy${
                locale === 'en-NZ' ? '/nz' : ''
              }/${learningModuleSlug}/${learningModule.videoList[0].slug}`}
            />
          </div>
        </div>
      </div>

      <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16 mb-16">
        <VideoListSwiper
          parentSlug={learningModule.slug}
          videoList={videoList}
          heading={`Module topics (${videoList.length})`}
          mode="dark"
        />
      </div>

      {learningModule.supportingDocumentations &&
        learningModule.supportingDocumentations.length > 0 && (
          <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16 mb-16">
            <p className="text-body-lg font-medium text-white mb-4">
              Supporting topic documentation
            </p>
            <div className="space-y-6">
              {learningModule.supportingDocumentations.map((doc, index) => (
                <SupportingDocumentCard
                  key={`doc-${index}`}
                  title={doc.title}
                  description={doc.description.description}
                  steps={doc.steps}
                />
              ))}
            </div>
          </div>
        )}

      <div className="xl:container mobile:px-4 tablet:px-8 lg:px-16 mb-12 md:mb-32 flex justify-center">
        <Button
          className="text-white"
          variant="quaternary"
          as="a"
          href={`/academy${locale === 'en-NZ' ? '/nz' : ''}`}
          label="Back to learning centre"
        />
      </div>
    </Layout>
  );
};

OverviewTemplate.propTypes = {
  pageContext: PropTypes.object.isRequired,
};

export default OverviewTemplate;
